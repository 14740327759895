export const GeneList = [
    'Prostate Cancer: AR Signaling=SOX9 RAN TNK2 EP300 PXN NCOA2 AR NRIP1 NCOR1 NCOR2',
    'Prostate Cancer: AR and steroid synthesis enzymes=AKR1C3 AR CYB5A CYP11A1 CYP11B1 CYP11B2 CYP17A1 CYP19A1 CYP21A2 HSD17B1 HSD17B10 HSD17B11 HSD17B12 HSD17B13 HSD17B14 HSD17B2 HSD17B3 HSD17B4 HSD17B6 HSD17B7 HSD17B8 HSD3B1 HSD3B2 HSD3B7 RDH5 SHBG SRD5A1 SRD5A2 SRD5A3 STAR',
    'Prostate Cancer: Steroid inactivating genes=AKR1C1 AKR1C2 AKR1C4 CYP3A4 CYP3A43 CYP3A5 CYP3A7 UGT2B15 UGT2B17 UGT2B7',
    'Prostate Cancer: Down-regulated by androgen=BCHE CDK8 CTBP1 ACKR3 DDC DPH1 FN1 HES6 MMP16 MYC PEG3 PIK3R3 PRKD1 SCNN1A SDC4 SERPINI1 SLC29A1 ST7 TULP4',
    'Glioblastoma: TP53 Pathway=CDKN2A MDM2 MDM4 TP53',
    'Glioblastoma: RTK/Ras/PI3K/AKT Signaling=EGFR ERBB2 PDGFRA MET KRAS NRAS HRAS NF1 SPRY2 FOXO1 FOXO3 AKT1 AKT2 AKT3 PIK3R1 PIK3CA PTEN',
    'Glioblastoma: RB Pathway=CDKN2A CDKN2B CDKN2C CDK4 CDK6 CCND2 RB1',
    'Ovarian Cancer: Oncogenes associated with epithelial ovarian cancer=RAB25 MECOM EIF5A2 PRKCI PIK3CA KIT FGF1 MYC EGFR NOTCH3 KRAS AKT1 ERBB2 PIK3R1 CCNE1 AKT2 AURKA',
    'Ovarian Cancer: Putative tumor-suppressor genes in epithelial ovarian cancer=DIRAS3 RASSF1 DLEC1 SPARC DAB2 PLAGL1 RPS6KA2 PTEN OPCML BRCA2 ARL11 WWOX TP53 DPH1 BRCA1 PEG3',
    'General: Cell Cycle Control=RB1 RBL1 RBL2 CCNA1 CCNB1 CDK1 CCNE1 CDK2 CDC25A CCND1 CDK4 CDK6 CCND2 CDKN2A CDKN2B MYC CDKN1A CDKN1B E2F1 E2F2 E2F3 E2F4 E2F5 E2F6 E2F7 E2F8 SRC JAK1 JAK2 STAT1 STAT2 STAT3 STAT5A STAT5B',
    'General: p53 signaling=TP53 MDM2 MDM4 CDKN2A CDKN2B TP53BP1',
    'General: Notch signaling=ADAM10 ADAM17 APH1A APH1B ARRDC1 CIR1 CTBP1 CTBP2 CUL1 DLL1 DLL3 DLL4 DTX1 DTX2 DTX3 DTX3L DTX4 EP300 FBXW7 HDAC1 HDAC2 HES1 HES5 HEYL ITCH JAG1 JAG2 KDM5A LFNG MAML1 MAML2 MAML3 MFNG NCOR2 NCSTN NOTCH1 NOTCH2 NOTCH3 NOTCH4 NRARP NUMB NUMBL PSEN1 PSEN2 PSENEN RBPJ RBPJL RFNG SNW1 SPEN HES2 HES4 HES7 HEY1 HEY2',
    'General: DNA Damage Response=CHEK1 CHEK2 RAD51 BRCA1 BRCA2 MLH1 MSH2 ATM ATR MDC1 PARP1 FANCF',
    'General: Other growth / proliferation signaling=CSF1 CSF1R IGF1 IGF1R FGF1 FGFR1 AURKA DLEC1 PLAGL1 OPCML DPH1',
    'General: Survival / cell death regulation signaling=NFKB1 NFKB2 CHUK DIRAS3 FAS HLA-G BAD BCL2 BCL2L1 APAF1 CASP9 CASP8 CASP10 CASP3 CASP6 CASP7 GSK3B ARL11 WWOX PEG3 TGFB1 TGFBR1 TGFBR2',
    'General: Telomere maintenance=TERC TERT',
    'General: RTK signaling family=EGFR ERBB2 ERBB3 ERBB4 PDGFA PDGFB PDGFRA PDGFRB KIT FGF1 FGFR1 IGF1 IGF1R VEGFA VEGFB KDR',
    'General: PI3K-AKT-mTOR signaling=PIK3CA PIK3R1 PIK3R2 PTEN PDPK1 AKT1 AKT2 FOXO1 FOXO3 MTOR RICTOR TSC1 TSC2 RHEB AKT1S1 RPTOR MLST8',
    'General: Ras-Raf-MEK-Erk/JNK signaling=KRAS HRAS BRAF RAF1 MAP3K1 MAP3K2 MAP3K3 MAP3K4 MAP3K5 MAP2K1 MAP2K2 MAP2K3 MAP2K4 MAP2K5 MAPK1 MAPK3 MAPK4 MAPK6 MAPK7 MAPK8 MAPK9 MAPK12 MAPK14 DAB2 RASSF1 RAB25',
    'General: Regulation of ribosomal protein synthesis and cell growth=RPS6KA1 RPS6KA2 RPS6KB1 RPS6KB2 EIF5A2 EIF4E EIF4EBP1 RPS6 HIF1A',
    'General: Angiogenesis=VEGFA VEGFB KDR CXCL8 CXCR1 CXCR2',
    'General: Folate transport=SLC19A1 FOLR1 FOLR2 FOLR3 IZUMO1R',
    'General: Invasion and metastasis=MMP1 MMP2 MMP3 MMP7 MMP9 MMP10 MMP11 MMP12 MMP13 MMP14 MMP15 MMP16 MMP17 MMP19 MMP21 MMP23B MMP24 MMP25 MMP26 MMP27 MMP28 ITGB3 ITGAV PTK2 CDH1 SPARC WFDC2',
];

export default {
    GeneList,
};
