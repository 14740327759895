const colors = [
    '#E64B35FF', '#4DBBD5FF', '#00A087FF', '#3C5488FF',
    '#F39B7FFF', '#8491B4FF', '#91D1C2FF', '#B09C85FF',
    '#0073C2FF', '#868686FF', '#CD534CFF', '#7AA6DCFF',
    '#003C67FF', '#3B3B3BFF', '#A73030FF', '#4A6990FF',
    '#00468BBF', '#42B540BF', '#0099B4BF', '#925E9FBF',
    '#FDAF91BF', '#AD002ABF', '#ADB6B6BF',
];

export default colors;
