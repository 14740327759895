export default {
    '--bg-color': '#0d4d78',
    '--main-font-color': '#212529',
    '--secondary-font-color': '#3c444d',
    '--link-color': '#4e90cf',
    '--table-bg-color': '#f0f2f5',
    black: '#000000',
    white: '#ffffff',
    blue: '#0033CC',
    green: '#1a9850',
    yellow: '#fed976',
    red: '#e41a1c',
    violet: '#8B00C9',
    brown: '#8B4513',
    lightgray: '#D3D3D3',
    silver: '#C0C0C0',
    amber_gradient: '#d8b365',
    white_gradient: '#f7f7f7',
    green_gradient: '#5ab4ac',
    fade_blue: '#ebebf5',
    white_smoke: '#F5F5F5',
    white_red: '#f2e7e4',
    pink_header: '#cc8168',
};
